import { ExRequest } from '@/utils/request'

/**
 * 保险列表查询
 * @returns
 */
export function getInsuranceList (params) {
  return ExRequest.post('/api/enterprise/employmentEmploy/insuranceController/getOrderInfo', null, {
    params: {
      ...params
    },
    headers: {
      clientId: 'enterprise'
    }
  })
}

/**
 * 电子签章地址获取
 * @returns
 */
export function getSignUrl (params) {
  return ExRequest.get('/api/insurance/getSignUrl', {
    params: {
      ...params
    },
    headers: {
      clientId: 'enterprise'
    }
  })
}
