<template>
  <a-form-model
    :model="query"
    class="search__form"
    :label-col="{span:5}"
    :wrapper-col="{span: 18}"
    labelAlign="left"
  >
    <a-row>
      <a-col :span="colspan">
        <a-form-model-item label="保险单号">
          <a-input placeholder="保险单号" v-model="query.orderNo" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="colspan">
        <a-form-model-item label="创建时间">
          <a-range-picker value-format="YYYY-MM-DD" v-model="query.date" />
        </a-form-model-item>
      </a-col>
      <a-col :span="colspan" >
        <a-form-model-item label="保险分类">
          <a-select :options="typeOptions" v-model="query.type" placeholder="请选择保险分类" allowClear></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="colspan" v-show="showStatus">
        <a-form-model-item label="保险状态">
          <a-select :options="statusOptions" v-model="query.insuranceStatus" placeholder="请选择保险状态" allowClear></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="showStatus ? 16 : 6">
        <div class="form_btn_box" v-perms="'search'">
          <a-button type="primary" @click="refresh">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

  <script>
export default {
  name: 'ProductHeader',
  props: {
    statusOptions: {
        type: Array,
        default: () => []
    },
    showStatus: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    colspan () {
      return this.showStatus ? 8 : 6
    }
  },
  data () {
    return {
      query: {},
      typeOptions: [
        {
          label: '雇主责任险',
          value: 'TZFG'
        },
        {
          label: '团体意外险',
          value: 'TEXC'
        }
      ]
    }
  },
  mounted () {
    this.roleType = this.handleGetDict('roleType')
  },
  methods: {
    reset () {
      this.$set(this, 'query', {
      })
    },
    refresh () {
      this.$emit('refresh')
    },
    getQuery () {
      const { date = [] } = this.query
      return {
        orderNo: this.query.orderNo,
        insuranceStatus: this.query.insuranceStatus,
        type: this.query.type,
        createTime: date.join(',')
      }
    }
  }
}
</script>

  <style lang="less">
.search__form {
  .form_btn_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
