import request from '@/utils/request'

// // 消息列表
// export function enterpriseSign (parameter) {
//   return request({
//     url: 'api/enterprise/employmentEmploy/enterpriseSign',
//     method: 'get',
//     params: parameter
//   })
// }

// // 购买保险
// export function ids (data) {
//   return request({
//     url: 'api/enterprise/employmentEmploy/enterpriseSign',
//     method: 'put',
//     data
//   })
// }
// // 导出数据
// export function enterpriseSigndownload () {
//   return request({
//     url: 'api/enterprise/employmentEmploy/enterpriseSign/download',
//     method: 'get'
//   })
// }

/**
 * 查询项目
 * @returns
 */
 export function getProjectGroup () {
  return request.get('/api/back/employmentEmploy/SelectController/queryAllProjectDepartmentByEnterpriseUserId')
}

/**
 * 查询项目部
 * @returns
 */
export function getProject (projectDepartmentId) {
  return request.get('/api/back/employmentEmploy/SelectController/queryAllProjectFromProjectDepartment', {
    params: {
      projectDepartmentId
    }
  })
}

/**
 * 查询子项目
 * @returns
 */
 export function getSonProject (projectId) {
  return request.get('/api/back/employmentEmploy/SelectController/queryAllWbProject', {
    params: {
      projectId
    }
  })
}

/**
 * 查询职位
 * @returns
 */
 export function getPosition (projectId) {
  return request.get('/api/back/employmentEmploy/SelectController/queryAllPositionInfoByProjectIdFromDemand', {
    params: {
      projectId,
      flag: true,
      flag2: true
    }
  })
}

/**
 * 查询投保雇员
 * @returns
 */
 export function getInsuranceUser (params) {
  return request.get('/api/enterprise/employmentEmploy/insuranceController/page', {
    params
  })
}

/**
 * 提交投保人雇员信息
 * @returns
 */
 export function postInsuranceUser (params) {
  return request.post('/api/enterprise/employmentEmploy/insuranceController', params)
}

/**
 * 保险列表查询
 * @returns
 */
 export function getInsuranceList (params) {
  return request.post('/api/enterprise/employmentEmploy/insuranceController/getOrderInfo', null, {
    params: {
      ...params
    }
  })
}

/**
 * 根据订单号查询订单信息
 * @returns
 */
 export function getOrderInfoByOrderNo (orderNo) {
  return request.post('/api/enterprise/employmentEmploy/insuranceController/getOrderInfoByOrderNo', null, {
    params: {
      orderNo
    }
  })
}

/**
 * 根据雇员信息流水号查询订单信息
 * @returns
 */
 export function getOrderInfoByNo (serialNo) {
  return request.post('/api/enterprise/employmentEmploy/insuranceController/getOrderInfoByNo', null, {
    params: {
      serialNo
    }
  })
}

/**
 * 获取电子签章地址
 * @returns
 */
 export function getSignUrl (orderNo) {
  return request.get('/api/enterprise/employmentEmploy/insuranceController/getSignUrl', {
    params: {
      orderNo
    }
  })
}

/**
 * 上传打款凭证
 * @returns
 */
 export function uploadFile (params) {
  return request.post('/api/enterprise/employmentEmploy/insuranceController/uploadFile', null, {
    params: {
      ...params
    }
  })
}

/**
 * 下载保单 返回下载地址
 * @returns
 */
 export function downloadPDF (orderNo) {
  return request.get('/api/enterprise/employmentEmploy/insuranceController/downloadPDF', {
    params: {
      orderNo
    }
  })
}
