<template>
  <a-modal v-model="visible" title="退款凭证查看" width="550px" class="pay-modal">
    <img
      @click="handleReview"
      :src="rowData.refundUrl"
      alt="图片丢失，清联系管理员"
      style="width: 500px;height: 200px"
    />
    <template slot="footer">
      <div>
        <a-button type="default" @click="visible = false">关闭</a-button>
      </div>
    </template>
  </a-modal>
</template>
      <script>
  export default {
    name: 'UploadPayment',
    data () {
      return {
        visible: false,
        rowData: {}
      }
    },
    methods: {
      $init (rowData) {
        this.rowData = rowData
        this.visible = true
      },
      handleReview () {
        this.$hevueImgPreview(this.rowData.refundUrl)
      }
    }
  }
  </script>

  <style lang="less">
  .pay-modal {
    img {
      cursor: zoom-in;
    }
  }
  </style>
