<template>
  <div class="insurance--wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      保险管理
    </div>
    <div class="content">
      <search-form
        ref="searchForm"
        @refresh="$refs.table.refresh()"
        :statusOptions="statusOptions"
        :showStatus="false"
      ></search-form>
      <p-table
        ref="table"
        row-key="orderNo"
        :scroll="{ y: 0 }"
        extraHeight="350"
        :sourceData="getDataApi"
        download
        :columns="columns"
        :pagination="false"
      >
        <!-- <template slot="headerLeft">
            <a-button type="primary" @click="handleAdd('TZFG')">雇责险购买</a-button>
            <a-button type="primary" @click="handleAdd('TEXC')">团意险购买</a-button>
          </template> -->
        <template slot="action" slot-scope="text, record">
          <!-- <a-button type="link" v-if="record.insuranceStatus === 1" @click="handleStep(record)">电子签章</a-button>
            <a-button type="link" v-if="record.insuranceStatus === 2" @click="handleStep(record)">上传打款凭证</a-button>
            <a-button type="link" v-if="record.insuranceStatus > 2" @click="handleStep(record)">订单进度</a-button> -->
          <a-button type="link" v-if="record.refundUrl" @click="handleOpenPayModal(record)" v-perms="'look'">查看退款凭证</a-button>
          <a-button
            type="link"
            v-if="record.insuranceStatus === 5"
            @click="handleDown(record.orderNo)"
            v-perms="'download-bd'"
          >下载保单</a-button
          >
          <a-button
            type="link"
            v-if="record.insuranceStatus === 5 && record.uploadStatus != 1"
            @click="handleOpen(record.orderNo)"
            v-perms="'dzqz'"
          >补电子签章</a-button
          >
        </template>
      </p-table>
    </div>
    <payModal ref="payModal" />
  </div>
</template>

<script>
import searchForm from '@/views/insurance/index/search-form.vue'
import { getDictListByNames } from '@/api/employmentSupport/dict'
import { downloadPDF } from '@/api/insurance'
import { getInsuranceList, getSignUrl } from '@/api/express-insurance'
import payModal from '@/views/insurance/index/pay-modal'

export default {
  name: 'Insurance',
  components: {
    searchForm,
    payModal
  },
  data () {
    return {
      statusOptions: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '保险订单号',
          dataIndex: 'orderNo',
          key: 'orderNo'
        },
        {
          title: '投保单号',
          dataIndex: 'proposalNo',
          key: 'proposalNo'
        },
        {
          title: '保险类型',
          dataIndex: 'proposalType',
          key: 'proposalType',
          customRender: (t, row) => {
            const type = row.proposalNo.substr(0, 4)
            return type === 'TZFG' ? '雇主责任险' : '团体意外险'
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          ellipsis: true
        },
        {
          title: '保险状态',
          dataIndex: 'insuranceStatus',
          key: 'insuranceStatus',
          customRender: (t) => {
            return this.statusOptions.find((item) => item.value == t)?.label
          },
          ellipsis: true
        },
        // {
        //   title: '失败原因',
        //   dataIndex: 'msg',
        //   key: 'msg',
        //   customRender: (t, r) => {
        //     if ([6, 7].includes(r.insuranceStatus)) {
        //       return t
        //     }
        //     return ''
        //   },
        //   ellipsis: true
        // },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableData: []
    }
  },
  computed: {
    enterpriseInfo () {
      return this.$store.getters.userInfo?.enterpriseInfoDto
    }
  },
  created () {
    this.initDict()
  },
  methods: {
    initDict () {
      getDictListByNames(['insurance_status']).then((res) => {
        this.statusOptions = res.data[0].detail.sort((a, b) => a.value - b.value)
      })
    },
    getDataApi (params) {
      const form = this.$refs.searchForm.getQuery()
      return getInsuranceList({
        pageIndex: 1,
        pageSize: 10000,
        ...form,
        insuranceStatus: 5,
        enterpriseNo: this.enterpriseInfo.creditCode
      })
    },
    handleAdd (type) {
      this.$router.push({
        path: '/insurance/insurance-create',
        query: {
          productFlag: type
        }
      })
    },
    handleStep ({ orderNo, proposalNo }) {
      this.$router.push({
        path: '/insurance/insurance-create',
        query: {
          orderNo,
          productFlag: proposalNo.substr(0, 4)
        }
      })
    },
    handleOpen (orderNo) {
      this.$spin.show()
      getSignUrl({
        orderNo,
        userId: this.$store.getters.userInfo.userId
      })
        .then((res) => {
          window.open(res.data)
          this.$confirm({
            title: '电子用印确认',
            content: '我已成功签订电子用印',
            onOk: () => {
              this.$refs.table.refresh()
            }
          })
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    handleDown (orderNo) {
      this.$spin.show()
      downloadPDF(orderNo)
        .then((res) => {
          window.open(res.data)
        })
        .finally(() => this.$spin.hide())
    },
    handleOpenPayModal (row) {
      this.$refs.payModal.$init(row)
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');
.insurance--wrapper {
  .table-btn {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
